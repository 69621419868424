/*------------------------------------*\
    $TABLES
\*------------------------------------*/
table {
  background-color: $table-bg;
}

.table {
  thead,
  tbody,
  tfoot {
    > tr {
      > th,
      > td {
        padding: $table-cell-padding;
        border-top: 1px solid $table-border-color;
      }
    }
  }

  thead > tr > th {
    border-bottom: 2px solid $table-border-color;
  }

  tbody + tbody {
    border-top: 2px solid $table-border-color;
  }

  .table {
    background-color: $main-content-bg;
  }
}

.table-bordered {
  border: 1px solid $table-border-color;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid $table-border-color;
      }
    }
  }
  > thead {
    > tr {
      > th,
      > td {
        border-bottom-width: 2px;
      }
    }
  }
}

.table-striped {
  > tbody {
    > tr:nth-child(odd) {
      > td,
      > th {
        background-color: $table-bg-accent;
      }
    }
  }
}

.table-hover {
  > tbody {
    > tr:hover {
      > td,
      > th {
        background-color: $table-bg-hover;
      }
    }
  }
}

.table > thead > tr,
.table > tbody > tr,
.table > tfoot > tr {
  > td.active,
  > th.active,
  &.active > td,
  &.active > th  {
    background-color: $table-bg-active;
  }
}

@include table-row-variant('success', $state-success-bg, $state-success-border);
@include table-row-variant('danger', $state-danger-bg, $state-danger-border);
@include table-row-variant('warning', $state-warning-bg, $state-warning-border);


@include sm-minus {
  .table-responsive {
    border: 1px solid $table-border-color;
  }
}

.table-condensed {
  thead,
  tbody,
  tfoot {
    > tr {
      > th,
      > td {
        padding: $table-condensed-cell-padding;
      }
    }
  }
}