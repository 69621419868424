.image-list-box {
  > li {
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    margin-right: 10px;
    vertical-align: top;

    > a {
      display: block;

      img {
        border: 4px solid $very-light;
        transition: border-color $transition-duration-base;
        border-radius: $border-radius-base;
      }

      &:hover {
        img {
          border-color: darken($very-light, 10%);
        }
      }
    }
  }
}