/*------------------------------------*\
    $LINKS
\*------------------------------------*/
a {
  color: $link-color;

  &:hover {
    color: darken($link-color, 5%);
  }
}

@for $i from 1 through length($colors) {
  $name: nth($colors-names, $i);
  $color: nth($colors, $i);

  a.link-#{$name} {
    color: $color;

    &:hover {
      color: darken($color, 5%);
    }
  }
}