@mixin xs-only { @media (max-width: $screen-sm - 1) { @content; } }
@mixin xs-only-land { @media (max-width: $screen-sm - 1) and (orientation: landscape) { @content; } }
@mixin xs-only-port { @media (max-width: $screen-sm - 1) and (orientation: portrait) { @content; } }
@mixin sm-only { @media (min-width: $screen-sm) and (max-width: $screen-sm-max) { @content; } }
@mixin md-only { @media (min-width: $screen-md) and (max-width: $screen-md-max) { @content; } }
@mixin lg-only { @media (min-width: $screen-lg) and (max-width: $screen-lg-max) { @content; } }

@mixin sm-minus { @media (max-width: $screen-sm - 1) { @content; } }
@mixin sm-plus { @media (min-width: $screen-sm) { @content; } }
@mixin md-plus { @media (min-width: $screen-md) { @content; } }
@mixin lg-plus { @media (min-width: $screen-lg) { @content; } }

@mixin val-plus($val) { @media (min-width: $val) { @content; } }
@mixin val-minus($val) { @media (max-width: $val - 1) { @content; } }

@mixin size($width, $height) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin vertical-align {
  display: table-cell;
  vertical-align: middle;
}

@mixin center-block {
  margin-left: auto;
  margin-right: auto;
}

@mixin right-block {
  margin-left: auto;
  margin-right: 0;
}

@mixin left-block {
  margin-left: 0;
  margin-right: auto;
}

@mixin font($size, $line: $size * 1.25) {
  font-size: floor($size);
  line-height: floor($line);
}

@mixin rpadding($padding, $multiplier: 1) {
  padding: (floor(nth($padding, 1) * $multiplier)) (floor(nth($padding, 2) * $multiplier)) (floor(nth($padding, 3) * $multiplier)) (floor(nth($padding, 4) * $multiplier));
}

@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    background-image: url($image);
    background-size: $width $height;
  }
}

@mixin placeholder($color: $placeholderText) {
  &:-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
}

@mixin rgba-fallback($color, $type, $fallback-bg: false) {
  @if $fallback-bg != false {
    #{$type}: rgba(mix(rgba($color, 1), $fallback-bg, opacity($color)*100), 1);
  } @else {
    #{$type}: rgba($color, 1);
  }
  #{$type}: $color;
}

@mixin img-responsive {
  display: block;
  height: auto;
  max-width: 100%;
}

@mixin ie-background-scale($url, $sizingmethod:'scale', $resetbackground: false) {
  @if $resetbackground { background-image: none; }
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='#{image-url($url, true)}', sizingMethod='#{$sizingmethod}');
  -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='#{image-url($url, true)}', sizingMethod='#{$sizingmethod}')";
}

@mixin table-row-variant($state, $background, $border) {
  .table > thead > tr,
  .table > tbody > tr,
  .table > tfoot > tr {
    > td.#{$state},
    > th.#{$state},
    &.#{$state} > td,
    &.#{$state} > th {
      background-color: $background;
      border-color: $border;
    }
  }

  .table-hover > tbody > tr {
    > td.#{$state}:hover,
    > th.#{$state}:hover,
    &.#{$state}:hover > td {
      background-color: darken($background, 5%);
      border-color: darken($border, 5%);
    }
  }
}

@mixin last-child {
  &:last-child { @content; }
  &.last-child { @content; }
}

@mixin nth-child-even {
  &:nth-child(even) { @content; }
  &.nth-child-even { @content; }
}

@mixin nth-child-odd {
  &:nth-child(odd) { @content; }
  &.nth-child-odd { @content; }
}