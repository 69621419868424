@mixin panel-custom {
  margin-bottom: 0;
  position: relative;
  display: block;
  text-decoration: none;
  display: block;
  border: 2px solid $panel-border-color;
  border-radius: $panel-border-radius;
  box-shadow: none;

  &:hover {
    text-decoration: none;
  }

  .panel-body {
    padding: 30px;

    p {
      color: $panel-description-color;
      line-height: 22px;
      margin: 0;
    }

    .panel-title {
      font-weight: 500;
      font-size: 22px;
      margin: 0 0 20px 0;
    }
  }

  .panel-icon {
    transition: top $transition-duration-base;
  }
}