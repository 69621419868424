/*------------------------------------*\
    $NAVS
\*------------------------------------*/
.list-group {
  .list-group-item {
    border-color: $very-light;

    [class^="fa-"], [class*=" fa-"] {
      color: $contrast;
    }

    &:hover, &:focus {
      background-color:  mix($very-light, $main-content-bg);
    }

    &.active, &.active:hover, &.active:focus {
      color: $white;
      background-color: $contrast;
      border-color: $contrast;

      [class^="fa-"], [class*=" fa-"] {
        color: $white;
      }
    }

    &:first-child {
      border-top-left-radius: $border-radius-base;
      border-top-right-radius: $border-radius-base;
    }

    &:last-child {
      border-bottom-left-radius: $border-radius-base;
      border-bottom-right-radius: $border-radius-base;
    }
  }
}

.nav-pills li + li {
  margin-left: 5px;
  margin-bottom: 5px;
}

.nav-pills > li > a {
  color: $medium;
  background-color: $very-light;

  &:hover, &:focus {
    background-color: darken($very-light, 5%);
  }
}

.nav-pills > li.active > a {
  padding: 5px 20px;
  color: $white;

  background-color: $contrast;

  &:hover, &:focus {
    background-color: $contrast;
  }
}