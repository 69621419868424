.portfolio-boxes {
  .portfolio-box {
    position: relative;
    margin-top: 20px;
    text-align: center;

    a {
      text-decoration: none;
      display: block;
      transition: color $transition-duration-base;

      &.image-link, .image-link {
        overflow: hidden;
        display: block;
        position: relative;
        bottom: 0;
        border-radius: $image-border-radius;
        transition: bottom $transition-duration-base;

        &:before {
          content: '';
          background-color: transparent;
          position: absolute;
          display: block;
          left: 0;
          top: 0;
          @include square(100%);
          transition: background-color $transition-duration-base;
          border-radius: $image-border-radius;
        }

        [class^="fa-"], [class*=" fa-"] {
          position: absolute;
          left: 50%;
          top: -50%;
          font-size: $portfolio-icon-size;
          margin-left: -($portfolio-icon-size)/2;
          margin-top: -($portfolio-icon-size)/2;
          color: $portfolio-icon-color;
          transition: top $transition-duration-base;
          text-shadow: 1px 1px 1px $black;
          @include square($portfolio-icon-size);
        }

        img {
          border-radius: $image-border-radius;
        }
      }

      &:hover {
        &.image-link, .image-link {
          bottom: 5px;

          &:before {
            @include rgba-fallback($portfolio-hover-background, "background-color");
          }

          [class^="fa-"], [class*=" fa-"] {
            top: 50%;
          }
        }

        .title {
          color: $portfolio-color-active;
        }

        .category {
          color: $portfolio-category-color-active;
        }
      }
    }

    .title {
      margin-top: 15px;
      margin-bottom: 5px;
      color: $portfolio-color;
      text-overflow: ellipsis;
      transition: color $transition-duration-base;
    }

    .category {
      color: $portfolio-category-color;
      margin: 0;
      font-size: 14px;
      text-overflow: ellipsis;
      transition: color $transition-duration-base;
    }
  }
}

#portfolio-filter {
  text-align: center;
  margin-bottom: 50px;

  ul.nav-pills {
    display: inline-block;

    li {
      display: inline;

      a {
        padding: 5px 20px;
        float: left;
      }
    }
  }
}

.lt-ie9 {
  .portfolio-boxes .portfolio-box a.image-link, .portfolio-boxes .portfolio-box a .image-link {
    [class^="fa-"], [class*=" fa-"] { display: none; }
    &:before { background-color: transparent; }
  }

  .portfolio-boxes .portfolio-box a.image-link, .portfolio-boxes .portfolio-box a .image-link {
    min-width: 100%;
  }
}